import React from "react";
import styles from "./Footer.module.scss";

import LogoChulaBook from "assets/images/logo_kengsob_footer.svg";
import CuIconFb from "assets/icons/socialMedia/cu-icon-facebook.png";
import CuIconTw from "assets/icons/socialMedia/cu-icon-twitter.png";
import CuIconIg from "assets/icons/socialMedia/cu-icon-instargam.png";
import CuIconLi from "assets/icons/socialMedia/cu-icon-line.png";
import { FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

import ReactGA from "react-ga";
import { TranslateLanguage } from "util/Translate";
import { vocabFooter } from "util/Translate.variable";

const translate = new TranslateLanguage();
translate.addMaping(vocabFooter);
const infoCUColumn = [
  {
    text: translate.convert(
      "อาคารวิทยกิตติ์ ชั้น 14 ซอย จุฬาลงกรณ์ 64 ถนน พญาไท แขวงวังใหม่ เขตปทุมวัน กรุงเทพ 10330"
    ),
    key: "location",
  },
];
const infoAppColumn = [
  {
    text: translate.convert("วิธีการสั่งซื้อ"),
    key: "howToBuy",
  },
  {
    text: translate.convert("วิธีการชำระเงิน"),
    key: "howToPay",
  },
  {
    text: translate.convert("การยกเลิกสินค้า"),
    key: "cancelOrder",
  },
];
const infoLawColumn = [
  {
    text: translate.convert("เงื่อนไขและข้อตกลง"),
    key: "term-condition",
  },
  {
    text: translate.convert("นโยบายความเป็นส่วนตัว"),
    key: "privacy-policy",
  },
];
const infoSupportColumn = [
  {
    text: translate.convert("TEL :  02-2189891"),
    key: "contact-number",
  },
  {
    text: translate.convert("Line:  @kengsob"),
    key: "line",
  },
  {
    text: translate.convert("Mail: kengsob@cubook.chula.ac.th"),
    key: "email",
  },
];

const Footer = (props) => {
  const sendGAEvent = (name) => {
    ReactGA.event({
      category: "Footer",
      action: "Click Menu footer",
      label: name,
    });
  };
  return (
    <div className={styles.divRoot}>
      <div className={styles.divImg} />
      <div className={styles.divPadding}>
        <div className={styles.divSiteMap}>
          <div className={styles.logo}>
            <img alt="Logo" src={LogoChulaBook} />
          </div>
          <div className={styles.wrapTitle}>
            <div className={styles.columnTitle}>
              <span className={styles.title}>
                {translate.convert("ศูนย์หนังสือจุฬาลงกรณ์มหาวิทยาลัย")}
              </span>
              {infoCUColumn.map((info) => {
                return (
                  <span
                    key={info.key}
                    className={styles.list}
                    onClick={() => {
                      sendGAEvent(info.key);
                      props.props.history.push(`/${info.key}`);
                    }}
                  >
                    {info.text}
                  </span>
                );
              })}
            </div>
            <div className={styles.columnTitle}>
              <span
                className={styles.title}
                onClick={() => props.props.history.push(`/help`)}
                style={{ cursor: "pointer" }}
              >
                {translate.convert("ช่วยเหลือ")}
              </span>
              {infoAppColumn.map((info) => {
                return (
                  <span
                    key={info.key}
                    className={styles.list}
                    onClick={() => {
                      sendGAEvent(info.key);
                      props.props.history.push(`/${info.key}`);
                    }}
                  >
                    {info.text}
                  </span>
                );
              })}
            </div>
            <div className={styles.columnTitle}>
              <span className={styles.title}>
                {translate.convert("ติตต่อเรา")}
              </span>
              {infoSupportColumn.map((info) => {
                return (
                  <span
                    key={info.key}
                    className={styles.list}
                    onClick={() => {
                      sendGAEvent(info.key);
                      props.props.history.push(`/${info.key}`);
                    }}
                  >
                    {info.text}
                  </span>
                );
              })}
            </div>
            <div className={styles.columnSocailTitle}>
              <span className={styles.title}>
                {translate.convert("โซเชียวเน็ตเวิร์ค")}
              </span>
              <span className={styles.listSocial}>
                <div
                  className={styles.Oval}
                  onClick={() => {
                    sendGAEvent("facebook");
                    window.open("https://www.facebook.com/cubook", "_blank");
                  }}
                >
                  <img alt="IconFb" src={CuIconFb} className={styles.imgIcon} />
                </div>
                <div
                  className={styles.Oval}
                  onClick={() => {
                    sendGAEvent("twitter");
                    window.open("https://twitter.com/Chulabook", "_blank");
                  }}
                >
                  <FaSquareXTwitter className={styles.imgIcon} />
                </div>
                <div
                  className={styles.Oval}
                  onClick={() => {
                    sendGAEvent("instagram");
                    window.open(
                      "https://www.instagram.com/chulabook/?hl=th",
                      "_blank"
                    );
                  }}
                >
                  <img alt="IconIg" src={CuIconIg} className={styles.imgIcon} />
                </div>
                <div
                  className={styles.Oval}
                  onClick={() => {
                    sendGAEvent("line");
                    window.open(
                      "https://www.youtube.com/user/ChulabookCU",
                      "_blank"
                    );
                  }}
                >
                  <FaYoutube className={styles.imgIcon} />
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className={styles.divCopyRight}>
          <div>
            <span className={styles.copyRight}>Powered by </span>
            <span className={styles.copyRightUnderLine}>
              Sobtid Edutainment Co., Ltd. products
            </span>
          </div>
          <div className={styles.sobtidLink}>
            <span style={{ color: "white", fontSize: 12 }}>
              Copyright © {new Date().getFullYear()} All rights reserved.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
