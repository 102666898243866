import _, { filter } from "lodash";
import {
  filterSuggestExam,
  filterSubject,
  filterBundleByFetch,
  filterBundleLabelByFetch,
  filterHarvest,
  specialOrder,
} from "pages/Home/utils/HomeUtils";

export const actionType = {
  START_FETCH_BUNDLE: "dataStore/START_FETCH_BUNDLE",
  START_FETCH_PRETEST: "dataStore/START_FETCH_PRETEST",

  FETCH_EDUCATION_TYPE: "dataStore/FETCH_EDUCATION_TYPE",
  FETCH_SUGGEST_EXAM: "dataStore/FETCH_SUGGEST_EXAM",
  FETCH_PUBLISHER: "dataStore/FETCH_PUBLISHER",
  FETCH_SUBJECT: "dataStore/FETCH_SUBJECT",
  FETCH_BUNDLE: "dataStore/FETCH_BUNDLE",
  FETCH_BUNDLE_USAGE: "dataStore/FETCH_BUNDLE_USAGE",
  FETCH_BUNDLE_RELATED: "dataStore/FETCH_BUNDLE_RELATED",
  FETCH_PRETEST: "dataStore/FETCH_PRETEST",
  FETCH_FILTER: "dataStore/FETCH_CORE_FILTER",
  FETCH_MY_EXAM: "dataStore/FETCH_MY_EXAM",
  FETCH_MY_FAVORITE: "dataStore/FETCH_MY_FAVORITE",
  FETCH_LESSON: "dataStore/FETCH_LESSON",

  CHANGE_SEARCH: "dataStore/CHANGE_SEARCH",
  CHANGE_DISPLAY_TYPE: "dataStore/CHANGE_DISPLAY_TYPE",
  CHANGE_FILTER_LEVEL: "dataStore/CHANGE_FILTER_LEVEL",
  CHANGE_HOVER: "dataStore/CHANGE_HOVER",
  CHANGE_ACTIVE_FILTER: "dataStore/CHANGE_ACTIVE_FILTER",
  CHANGE_FILTER: "dataStore/CHANGE_FILTER",
  CHANGE_FILTER_GRADE: "dataStore/CHANGE_FILTER_GRADE",
  CHANGE_FILTER_SUBJECT: "dataStore/CHANGE_FILTER_SUBJECT",
  CHANGE_FILTER_PUBLISHER: "dataStore/CHANGE_FILTER_PUBLISHER",
  CHANGE_FILTER_LESSON: "dataStore/CHANGE_FILTER_LESSON",
  CHANGE_LEVEL: "dataStore/CHANGE_LEVEL",
  CHANGE_BUNDLE_TESTING_DATA: "dataStore/CHANGE_BUNDLE_TESTING_DATA",
  CHANGE_PAYMENT_METHOD: "dataStore/CHANGE_PAYMENT_METHOD",
  CHANGE_PAYMENT_DATA: "dataStore/CHANGE_PAYMENT_DATA",

  RESET_FILTER: "dataStore/RESET_FILTER",
};

export const initialState = {
  displayType: "card",
  educationType: {
    data: [],
    activeLevel: "",
    loading: true,
  },
  filter: {
    filter: {
      level: {
        selected: "",
        existLabel: [],
      },
      grade: {
        selected: "",
        existLabel: [],
      },
      subject: {
        selected: "",
        existLabel: [],
      },
      publisher: {
        selected: "",
      },
      lesson: {
        selected: "",
        existLabel: [],
      },
    },
    sys: {
      data: [],
      loading: true,
    },
  },
  allSubject: [],
  allGrade: [],
  suggestExam: {
    data: [],
    filteredHome: [],
    filteredSuggest: [],
    loading: true,
  },
  search: "",
  bundle: {
    data: [],
    option: {
      activeFilter: false,
      hover: {
        isFilterHover: false,
        isSortHover: false,
      },
    },
    loading: true,
  },
  pretest: {
    data: [],
    option: {
      activeFilter: false,
      hover: {
        isFilterHover: false,
        isSortHover: false,
      },
    },
    loading: true,
  },
  bundleUsage: {
    data: [],
    testing: {
      amountQuestions: "",
      categories: [],
      grade: "",
      id: "",
      name: "",
      subject: "",
    },
    loading: true,
  },
  bundleRelated: {
    data: [],
    loading: true,
  },
  myExam: {
    bundle: {
      loading: true,
      data: [],
    },
    pretest: {
      loading: true,
      data: [],
    },
  },
  myFavorite: {
    bundle: {
      loading: true,
      data: [],
    },
    pretest: {
      loading: true,
      data: [],
    },
  },
  pretestUsage: {
    data: [],
    loading: true,
  },
  publisher: {
    data: [],
    loading: true,
  },
  subject: {
    data: [],
    loading: true,
  },
  purchaseData: {
    step: undefined,
    transactionType: undefined,
    paymentMethod: undefined,
    orderData: {
      id: undefined,
      name: undefined,
      publisher: undefined,
      cover: undefined,
      price: undefined,
    },
    paymentData: undefined,
  },
};

export const dataReducer = (state, action) => {
  //! FETCH
  switch (action.type) {
    case actionType.FETCH_EDUCATION_TYPE:
      const { modEd, active } = action.payload;
      return {
        ...state,
        educationType: {
          ...state.educationType,
          data: modEd,
          activeLevel: active.id,
          loading: false,
        },
      };
    case actionType.FETCH_SUGGEST_EXAM:
      const filteredExamByFetch = filterSuggestExam(
        action.payload.suggestExamData,
        state.educationType.activeLevel
      );

      return {
        ...state,
        suggestExam: {
          data: action.payload.suggestExamData,
          filtered: filteredExamByFetch,
          loading: false,
        },
      };
    case actionType.FETCH_PUBLISHER:
      return {
        ...state,
        publisher: {
          data: action.payload,
          loading: false,
        },
      };
    case actionType.FETCH_SUBJECT:
      const filteredSubjectByFetch = filterSubject(action.payload);
      const filteredAndOrderedSubjectByFetch = specialOrder(
        filteredSubjectByFetch
      );

      return {
        ...state,
        subject: {
          data: filteredAndOrderedSubjectByFetch,
          loading: false,
        },
      };
    case actionType.START_FETCH_BUNDLE:
      return {
        ...state,
        bundle: {
          ...state.bundle,
          loading: true,
        },
      };
    case actionType.FETCH_BUNDLE:
      const { bundle } = action.payload;

      return {
        ...state,
        bundle: {
          ...state.bundle,
          data: bundle,
          loading: false,
        },
      };
    case actionType.FETCH_LESSON:
      return {
        ...state,
        filter: {
          ...state.filter,
          filter: {
            ...state.filter.filter,
            lesson: {
              existLabel: action.payload.lesson,
              selected: "",
            },
          },
        },
      };
    case actionType.FETCH_BUNDLE_USAGE:
      return {
        ...state,
        bundleUsage: {
          ...state.bundleUsage,
          data: action.payload,
          loading: false,
        },
      };
    case actionType.FETCH_BUNDLE_RELATED:
      return {
        ...state,
        bundleRelated: {
          data: action.payload,
          loading: false,
        },
      };
    case actionType.START_FETCH_PRETEST:
      return {
        ...state,
        pretest: {
          ...state.pretest,
          loading: true,
        },
      };
    case actionType.FETCH_PRETEST:
      return {
        ...state,
        pretest: {
          ...state.pretest,
          data: action.payload,
          option: {
            ...state.pretest.option,
          },
          loading: false,
        },
      };
    case actionType.FETCH_FILTER:
      const filterRes = filterHarvest({
        core: action.payload.core,
        level: state.educationType.activeLevel,
      });

      return {
        ...state,
        allSubject: action.payload.allSubject,
        allGrade: action.payload.allGrade,
        filter: {
          filter: {
            ...state.filter.filter,
            level: {
              ...state.filter.filter.level,
              selected: state.educationType.activeLevel,
              existLabel: filterRes.level,
            },
            grade: {
              ...state.filter.filter.grade,
              existLabel: filterRes.grade,
            },
          },
          sys: {
            data: action.payload,
            loading: false,
          },
        },
      };
    case actionType.FETCH_MY_EXAM:
      return {
        ...state,
        myExam: {
          ...state.myExam,
          bundle: {
            loading: false,
            data: action.payload.bundle,
          },
          pretest: {
            loading: false,
            data: action.payload.pretest,
          },
        },
      };
    case actionType.FETCH_MY_FAVORITE:
      return {
        ...state,
        myFavorite: {
          ...state.myFavorite,
          bundle: {
            loading: false,
            data: action.payload.bundle,
          },
          pretest: {
            loading: false,
            data: action.payload.pretest,
          },
        },
      };

    //! CHANGE
    case actionType.CHANGE_SEARCH:
      state.search = action.payload;

      return {
        ...state,
      };
    case actionType.CHANGE_DISPLAY_TYPE:
      return {
        ...state,
        displayType: action.payload,
      };
    case actionType.CHANGE_HOVER:
      return {
        ...state,
        bundle: {
          ...state.bundle,
          option: {
            ...state.bundle.option,
            hover: {
              ...state.bundle.option.hover,
              isFilterHover: action.payload.isFilterHover,
              isSortHover: action.payload.isSortHover,
            },
          },
        },
      };
    case actionType.CHANGE_ACTIVE_FILTER:
      const level = state.educationType.activeLevel;

      const filterByLevelActive = _.find(state.filter.sys.data.core, (core) => {
        return core.id === level;
      });
      const gradeFilterActive = _.map(filterByLevelActive.grades, (grade) => ({
        id: grade.id,
        name: grade.name,
      }));

      return {
        ...state,
        bundle: {
          ...state.bundle,
          option: {
            ...state.bundle.option,
            activeFilter: action.payload,
          },
        },
        filter: {
          ...state.filter,
          filter: {
            ...state.filter.filter,
            ...state.filter.sys,
            level: {
              ...state.filter.filter.level,
              selected: level,
            },
            grade: {
              existLabel: gradeFilterActive,
              selected: undefined,
            },
          },
        },
      };
    case actionType.RESET_FILTER: {
      return {
        ...state,
        bundle: {
          ...state.bundle,
          option: {
            ...state.bundle.option,
            activeFilter: false,
          },
        },
        filter: {
          ...state.filter,
          filter: {
            level: {
              ...state.filter.filter.level,
              selected: "",
            },
            grade: {
              ...state.filter.filter.grade,
              selected: "",
            },
            subject: {
              ...state.filter.filter.subject,
              selected: "",
            },
            publisher: {
              ...state.filter.filter.publisher,
              selected: "",
            },
            lesson: {
              ...state.filter.filter.lesson,
              selected: "",
            },
          },
        },
      };
    }
    case actionType.CHANGE_FILTER_LEVEL:
      const { id: levelId } = action.payload;

      const filterByLevel = _.find(state.filter.sys.data.core, (core) => {
        return core.id === levelId;
      });
      const gradeFilter = _.map(filterByLevel.grades, (grade) => ({
        id: grade.id,
        name: grade.name,
      }));

      return {
        ...state,
        educationType: {
          ...state.educationType,
          activeLevel: levelId,
        },
        filter: {
          ...state.filter,
          filter: {
            ...state.filter.filter,
            level: {
              ...state.filter.filter.level,
              selected: levelId,
            },
            grade: {
              existLabel: gradeFilter,
              selected: undefined,
            },
            subject: {
              existLabel: [],
              selected: undefined,
            },
          },
        },
      };
    case actionType.CHANGE_FILTER_GRADE:
      const { id } = action.payload;
      const isSelected = id === state.filter.filter.grade.selected;

      const coreData = _.find(
        state.filter.sys.data.core,
        (core) => core.id === state.filter.filter.level.selected
      );
      const gradeData = _.find(
        _.get(coreData, "grades", []),
        (grade) => grade.id === id
      );

      return {
        ...state,
        filter: {
          ...state.filter,
          filter: {
            ...state.filter.filter,
            grade: {
              ...state.filter.filter.grade,
              selected: isSelected ? undefined : id,
            },
            subject: {
              existLabel: isSelected ? [] : gradeData?.subjects || [],
              selected: undefined,
            },
          },
        },
      };

    case actionType.CHANGE_FILTER_SUBJECT:
      const { id: subId } = action.payload;

      if (_.isEqual(state.filter.filter.subject.selected, subId)) {
        state.filter.filter.subject.selected = undefined;
      } else {
        state.filter.filter.subject.selected = subId;
      }
      return { ...state };
    case actionType.CHANGE_FILTER_PUBLISHER:
      return {
        ...state,
        filter: {
          ...state.filter,
          filter: {
            ...state.filter.filter,
            publisher: {
              ...state.filter.filter.publisher,
              selected: action.payload,
            },
          },
        },
      };
    case actionType.CHANGE_FILTER_LESSON:
      if (_.isEqual(state.filter.filter.lesson.selected, action.payload)) {
        state.filter.filter.lesson.selected = undefined;
      } else {
        state.filter.filter.lesson.selected = action.payload;
      }
      return { ...state };
    case actionType.CHANGE_LEVEL:
      if (!_.isEmpty(action.payload)) {
        const filteredSubjectByLevel = filterSubject(
          state.subject.data,
          action.payload
        );

        return {
          ...state,
          educationType: {
            ...state.educationType,
            activeLevel: action.payload,
          },
          bundle: {
            ...state.bundle,
            data: action.payload.bundle,
          },
          pretest: {
            ...state.pretest,
            data: action.payload.pretest,
          },
          subject: {
            ...state.subject,
            filtered: filteredSubjectByLevel,
          },
        };
      }
    case actionType.CHANGE_BUNDLE_TESTING_DATA:
      return {
        ...state,
        bundleUsage: { ...state.bundleUsage, testing: action.payload },
      };
    case actionType.CHANGE_PURCHASE_DATA:
      return { ...state, purchaseData: action.payload };
    case actionType.CHANGE_PAYMENT_METHOD:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          paymentMethod: action.payload,
        },
      };
    case actionType.CHANGE_PAYMENT_DATA:
      return {
        ...state,
        purchaseData: {
          ...state.purchaseData,
          paymentData: action.payload,
        },
      };
    default:
      return state;
  }
};
